export const APP_VERSION = '1.1.2';
export const API_URL_COINCAP = 'https://api.coincap.io/v2';
export const API_URL_SOCKET = 'wss://ws.coincap.io';
export const APP_DIVIDER = '|';
export const APP_TITLE_PREFFIX = 'Bitcoin + Altcoin Prices | BTC, XMR, XRP, ETH & more! - CoinPrice.fyi';
export const APP_NAME = 'CoinPrice.fyi';
export const APP_MAIL = 'support@coinprice.fyi';
export const CHANGELLY_REF_ID = 'by2azfzttb0uj9sk'; //PAST YOUR CHANGELLY REFERAL ID
export const COINS_LIMIT = 50; // Max size 2000 per request
export const SYMBOL_EMPTY = '–';
