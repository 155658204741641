const ICON_NAMES_ARRAY = [
  'act',
  'ada',
  'ae',
  'aion',
  'amp',
  'ant',
  'ardr',
  'ark',
  'ast',
  'atm',
  'bat',
  'bay',
  'bcc',
  'bch',
  'bcn',
  'bco',
  'bdl',
  'bela',
  'blcn',
  'blk',
  'block',
  'bnb',
  'bnt',
  'bq',
  'bqx',
  'btc',
  'btcd',
  'btcz',
  'btg',
  'btm',
  'bts',
  'btx',
  'burst',
  'cdn',
  'clam',
  'cloak',
  'cnd',
  'cnx',
  'cny',
  'cred',
  'crpt',
  'cvc',
  'dash',
  'dat',
  'data',
  'dbc',
  'dcn',
  'dcr',
  'dent',
  'dgb',
  'dgd',
  'doge',
  'drgn',
  'edg',
  'edoge',
  'elf',
  'ella',
  'emc',
  'emc2',
  'eng',
  'eos',
  'etc',
  'eth',
  'ethos',
  'etn',
  'etp',
  'eur',
  'evx',
  'exmo',
  'exp',
  'fair',
  'fct',
  'fil',
  'fldc',
  'flo',
  'ftc',
  'fun',
  'game',
  'gas',
  'gbp',
  'gbx',
  'gbyte',
  'gno',
  'gnt',
  'grc',
  'grs',
  'gup',
  'gvt',
  'gxs',
  'hpb',
  'hsr',
  'huc',
  'hush',
  'icn',
  'icx',
  'ignis',
  'ins',
  'iop',
  'iost',
  'jpy',
  'kcs',
  'kin',
  'kmd',
  'knc',
  'krb',
  'lbc',
  'link',
  'lkk',
  'lrc',
  'lsk',
  'ltc',
  'maid',
  'mana',
  'mcap',
  'mco',
  'med',
  'miota',
  'mkr',
  'mln',
  'mnx',
  'mona',
  'mtl',
  'music',
  'nas',
  'nav',
  'ndz',
  'nebl',
  'neo',
  'neos',
  'ngc',
  'nlc2',
  'nlg',
  'nmc',
  'nxs',
  'nxt',
  'omg',
  'omni',
  'ost',
  'ox',
  'pac',
  'part',
  'pasl',
  'pay',
  'pink',
  'pirl',
  'pivx',
  'plr',
  'poe',
  'pot',
  'powr',
  'ppc',
  'ppp',
  'ppt',
  'prl',
  'pura',
  'qash',
  'qiwi',
  'qlc',
  'qsp',
  'qtum',
  'rads',
  'rcn',
  'rdd',
  'rdn',
  'rep',
  'req',
  'rhoc',
  'ric',
  'rise',
  'rlc',
  'rpx',
  'rub',
  'salt',
  'san',
  'sbd',
  'sberbank',
  'sc',
  'sky',
  'smart',
  'sngls',
  'snt',
  'sphtx',
  'srn',
  'start',
  'steem',
  'storj',
  'storm',
  'strat',
  'sub',
  'sys',
  'taas',
  'tau',
  'tix',
  'tkn',
  'tnc',
  'trig',
  'trx',
  'tzc',
  'ubq',
  'usd',
  'usdt',
  'ven',
  'veri',
  'via',
  'vivo',
  'vrc',
  'vtc',
  'wabi',
  'waves',
  'wax',
  'wtc',
  'xbc',
  'xcp',
  'xdn',
  'xem',
  'xlm',
  'xmg',
  'xmr',
  'xmy',
  'xp',
  'xpa',
  'xpm',
  'xrb',
  'xrp',
  'xtz',
  'xuc',
  'xvc',
  'xvg',
  'xzc',
  'zcl',
  'zec',
  'zen',
  'zrx',
];

let ICON_NAMES = {};

ICON_NAMES_ARRAY.forEach((name) => {
  ICON_NAMES[name] = true;
});

export const pickSvgUrl = (code) => {
  let name = code.toLowerCase();
  let svgUrl;
  if (ICON_NAMES[name]) {
    svgUrl = require(`./assets/cryptocurrency-icons/svg/color/${name}.svg`);
  } else {
    svgUrl = require(`./assets/cryptocurrency-icons/empty.svg`);
  }
  return svgUrl;
}

export default ICON_NAMES;
